import { useField } from 'formik'

const TextareaField = ({ children, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <textarea
            className={`form-control ${
                meta.touched && meta.error ? 'is-invalid' : ''
            }`}
            {...field}
            {...props}
        >
            {children}
        </textarea>
    )
}

export default TextareaField
