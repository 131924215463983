import { useField } from 'formik'
import FormGroup from './FormGroup'
import PasswordField from './PasswordField'
import TextareaField from './TextareField'
import SelectDropdown from './SelectDropdown'

const Field = ({
    label,
    type,
    className,
    hideFormGroup,
    showAsterisk,
    ...props
}) => {
    const [field, meta] = useField(props)

    return (
        <FormGroup
            hideFormGroup={hideFormGroup}
            label={label}
            name={field.name}
            required={showAsterisk || props.required}
            isError={meta.touched && meta.error}
        >
            <div
                className={`${
                    props.textaddon
                        ? 'input-group pl-0'
                        : 'form-check pl-0 w-100'
                } ${meta.error ? 'is-invalid' : ''}`}
            >
                {type === 'select' ? (
                    <SelectDropdown {...field} {...props} />
                ) : type === 'textarea' ? (
                    <TextareaField {...field} {...props} />
                ) : type === 'password' ? (
                    <PasswordField {...field} {...props} />
                ) : (
                    <React.Fragment>
                        <input
                            type={type}
                            className={`form-control ${
                                meta.touched && meta.error ? 'is-invalid' : ''
                            } ${className} || '' `}
                            {...field}
                            {...props} // other props like required is auto put into input field
                        />
                        {props.textaddon && (
                            <div className="input-group-append">
                                <span className="input-group-text bg-white">
                                    {props.textaddon}
                                </span>
                            </div>
                        )}
                    </React.Fragment>
                )}

                {meta.touched && meta.error ? (
                    <div className="invalid-feedback">{meta.error}</div>
                ) : null}
            </div>
        </FormGroup>
    )
}

export default Field
