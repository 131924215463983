export const FormGroup = ({
    hideFormGroup,
    required,
    children,
    name,
    label,
    isError
}) => {
    return (
        <>
            {hideFormGroup !== true ? (
                <div className="form-group w-100">
                    {label && (
                        <label
                            htmlFor={name}
                            className={`form-check-label ${
                                isError ? 'text-danger' : ''
                                } ${required ? 'required' : ''}`}
                        >
                            {label}
                        </label>
                    )}
                    {children}
                </div>
            ) : (
                    <>{children}</>
                )}
        </>
    )
}

export default FormGroup
