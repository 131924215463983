const SelectDropdown = ({ children, className, ...props }) => {
    return (
        <div>
            {
                <select
                    className={`custom-select ${className || ''}`}
                    {...props}
                >
                    {children}
                </select>
            }
        </div>
    )
}

export default SelectDropdown
