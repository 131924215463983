import { useState, useEffect } from 'react'
import { useField } from 'formik'
import Icon from '@/components/Common/Icon'
import css from '../../styles/components/form/password-field.scss'

const PasswordField = ({ type, passwordVisible, isShowPassword, ...props }) => {
    const [field, meta] = useField(props)

    const [showPassword, setShowPassword] = useState(
        props.isShowPassword ? props.isShowPassword : false
    )

    const toggleShow = () => {
        if (props.onToggleChange && typeof props.onToggleChange === 'function')
            props.onToggleChange(!showPassword)
        setShowPassword(!showPassword)
    }

    useEffect(() => {
        if (props.isShowPassword) setShowPassword(props.isShowPassword)
    }, [props.isShowPassword])

    return (
        <div
            className={`${css['password-field']} ${
                meta.touched && meta.error ? 'is-invalid' : ''
            }`}
        >
            <input
                type={showPassword ? 'text' : 'password'}
                className={`form-control ${
                    meta.touched && meta.error ? 'is-invalid' : ''
                }`}
                {...props}
            />
            {passwordVisible && (
                <PasswordFieldEye slash={showPassword} onClick={toggleShow} />
            )}
        </div>
    )
}

PasswordField.initialProps = {
    isShowPassword: false
}

const PasswordFieldEye = ({ slash, onClick }) => {
    return (
        <button type="button" className="btn btn-link-dark" onClick={onClick}>
            <Icon name={slash ? 'eye-slash' : 'eye'} fixedWidth />
        </button>
    )
}

PasswordField.Eye = PasswordFieldEye

export default PasswordField
